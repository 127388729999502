<template>
  <div>
    <div class="pageTitle">案場狀態</div>
    <div class="borderColor mb-4">
      <div class="d-flex flex-wrap align-items-center m-2 mb-3">
        <div class="inputDiv mr-2">
          <i class="fas fa-search mr-2" />
          <input
            v-model="searchInput"
            type="text"
            class="filterInput"
            :placeholder="'依' + selectValue.name + '搜尋'"
          />
          <span class="focus-border" />
        </div>

        <select v-model="selectValue" class="mr-4">
          <option v-for="(i, index) in filterTitle" :key="index" :value="i">
            {{ i.name }}
          </option>
        </select>

        <span class="font-weight-bold">依區域篩選:&ensp;</span>
        <select v-model="filterSite" class="mr-4">
          <option
            v-for="(i, index) in filterSiteArray"
            :key="index"
            :value="i.value"
          >
            {{ i.name }}
          </option>
        </select>

        <span class="font-weight-bold">
          資料筆數:&ensp;{{ factoryStatusFilterSearch.length }}
        </span>
        <button
          type="button"
          class="confirmButton p-1 my-1 ml-auto mr-3"
          :class="{ disableButton: loadingData }"
          style="width: 80px"
          @click="getRecentAlarms"
        >
          更新
        </button>

        <button
          type="button"
          class="confirmButton py-1 my-1"
          style="width: 120px"
          @click="goMoxaPage"
        >
          MOXA 頁面
        </button>
      </div>

      <b-overlay :show="loadingData">
        <div class="otherTableStyle tableWrap">
          <table class="w-100 text-center text-nowrap">
            <tr class="sticky-top">
              <th
                v-for="(i, index) in thTitle"
                :key="index"
                class="sort"
                :class="{ down: !i.sort, up: i.sort }"
                @click="sortHistoryData(i.sortName, index)"
              >
                {{ i.name }}
              </th>
              <th>設定</th>
            </tr>
            <tr v-for="(i, idx) in factoryStatusFilterSearch" :key="idx">
              <td>{{ i.factoryName }}</td>
              <td>{{ i.projectNumber }}</td>
              <td>{{ i.county }}</td>
              <td>{{ i.maintainer }}</td>
              <td>{{ i.maintainerNum }}</td>
              <td>
                <i
                  class="fas fa-wifi statusIcon"
                  :id="i.factoryName + 'disconnect'"
                  :style="StatusFunction(i.statusResult.disconnect)"
                />
                <i
                  class="fas fa-car-battery statusIcon"
                  :id="i.factoryName + 'invs'"
                  :style="StatusFunction(i.statusResult.invsTotalStatus)"
                />
                <i
                  class="fas fa-bolt statusIcon cursor-pointer"
                  :id="i.factoryName + 'meter'"
                  :style="StatusFunction(i.statusResult.meter)"
                  @click="
                    showMeter(
                      i.factoryName,
                      StatusFunction(i.statusResult.meter)
                    )
                  "
                />
                <i
                  class="fas fa-sun statusIcon"
                  :id="i.factoryName + 'irr'"
                  :style="StatusFunction(i.statusResult.irr)"
                />
                <i
                  class="far fa-square statusIcon"
                  :id="i.factoryName + 'moduleTemp'"
                  :style="StatusFunction(i.statusResult.moduleTemp)"
                />
                <i
                  class="fas fa-thermometer-half statusIcon"
                  :id="i.factoryName + 'envTemp'"
                  :style="StatusFunction(i.statusResult.envTemp)"
                />
                <i
                  class="fas fa-wind statusIcon"
                  :id="i.factoryName + 'windSpeed'"
                  :style="StatusFunction(i.statusResult.windSpeed)"
                />
                <i
                  class="fas fa-water statusIcon"
                  :id="i.factoryName + 'waterLevel'"
                  :style="StatusFunction(i.statusResult.waterLevel)"
                />
              </td>
              <td>{{ i.timestamp }}</td>
              <td
                class="cursor-pointer"
                @click.stop="
                  openSetting(i.config, i.factoryName, i.docId, i.defaultConfig)
                "
              >
                <i class="fas fa-sliders-h" />
              </td>
              <b-popover
                placement="rightbottom"
                :target="i.factoryName + 'disconnect'"
                triggers="hover"
              >
                <template #title>資料蒐集器</template>
                <div class="popoverContent">
                  {{ i.statusResult.disconnect ? "斷線" : "正常" }}
                </div>
              </b-popover>
              <b-popover
                placement="rightbottom"
                :target="i.factoryName + 'invs'"
                triggers="hover"
              >
                <template #title>變流器</template>
                <div class="popoverContent">
                  <div
                    v-if="
                      i.statusResult.invsTotalStatus &&
                      i.statusResult.invsTotalStatus.length === 0
                    "
                  >
                    正常
                  </div>
                  <div
                    v-else-if="
                      i.statusResult.invsTotalStatus &&
                      i.statusResult.invsTotalStatus.length > 0
                    "
                  >
                    <p
                      v-for="(x, idx) in i.statusResult.invsTotalStatus"
                      :key="idx"
                    >
                      {{ x.msg }}
                    </p>
                  </div>
                  <div v-else>無裝置或是未填設定值</div>
                </div>
              </b-popover>
              <b-popover
                placement="rightbottom"
                :target="i.factoryName + 'meter'"
                triggers="hover"
              >
                <template #title>電表</template>
                <div class="popoverContent">
                  <div
                    v-if="
                      i.statusResult.meter && i.statusResult.meter.length === 0
                    "
                  >
                    正常
                  </div>
                  <div
                    v-else-if="
                      i.statusResult.meter && i.statusResult.meter.length > 0
                    "
                  >
                    <p v-for="(x, idx) in i.statusResult.meter" :key="idx">
                      {{ x.msg }}
                    </p>
                  </div>
                  <div v-else>無裝置或是未填設定值</div>
                </div>
              </b-popover>
              <b-popover
                placement="rightbottom"
                :target="i.factoryName + 'irr'"
                triggers="hover"
                class="test"
              >
                <template #title>日照</template>
                <div class="popoverContent">
                  <div
                    v-if="i.statusResult.irr && i.statusResult.irr.length === 0"
                  >
                    正常
                  </div>
                  <div
                    v-else-if="
                      i.statusResult.irr && i.statusResult.irr.length > 0
                    "
                  >
                    <p v-for="(x, idx) in i.statusResult.irr" :key="idx">
                      {{ x.msg }}
                    </p>
                  </div>
                  <div v-else>無裝置或是未填設定值</div>
                </div>
              </b-popover>
              <b-popover
                placement="rightbottom"
                :target="i.factoryName + 'moduleTemp'"
                triggers="hover"
              >
                <template #title>模溫</template>
                <div class="popoverContent">
                  <div
                    v-if="
                      i.statusResult.moduleTemp &&
                      i.statusResult.moduleTemp.length === 0
                    "
                  >
                    正常
                  </div>
                  <div
                    v-else-if="
                      i.statusResult.moduleTemp &&
                      i.statusResult.moduleTemp.length > 0
                    "
                  >
                    <p v-for="(x, idx) in i.statusResult.moduleTemp" :key="idx">
                      {{ x.msg }}
                    </p>
                  </div>
                  <div v-else>無裝置或是未填設定值</div>
                </div>
              </b-popover>
              <b-popover
                placement="rightbottom"
                :target="i.factoryName + 'envTemp'"
                triggers="hover"
              >
                <template #title>環溫</template>
                <div class="popoverContent">
                  <div
                    v-if="
                      i.statusResult.envTemp &&
                      i.statusResult.envTemp.length === 0
                    "
                  >
                    正常
                  </div>
                  <div
                    v-else-if="
                      i.statusResult.envTemp &&
                      i.statusResult.envTemp.length > 0
                    "
                  >
                    <p v-for="(x, idx) in i.statusResult.envTemp" :key="idx">
                      {{ x.msg }}
                    </p>
                  </div>
                  <div v-else>無裝置或是未填設定值</div>
                </div>
              </b-popover>
              <b-popover
                placement="rightbottom"
                :target="i.factoryName + 'windSpeed'"
                triggers="hover"
              >
                <template #title>風速</template>
                <div class="popoverContent">
                  <div
                    v-if="
                      i.statusResult.windSpeed &&
                      i.statusResult.windSpeed.length === 0
                    "
                  >
                    正常
                  </div>
                  <div
                    v-else-if="
                      i.statusResult.windSpeed &&
                      i.statusResult.windSpeed.length > 0
                    "
                  >
                    <p v-for="(x, idx) in i.statusResult.windSpeed" :key="idx">
                      {{ x.msg }}
                    </p>
                  </div>
                  <div v-else>無裝置或是未填設定值</div>
                </div>
              </b-popover>
              <b-popover
                placement="rightbottom"
                :target="i.factoryName + 'waterLevel'"
                triggers="hover"
              >
                <template #title>水位</template>
                <div class="popoverContent">
                  <div
                    v-if="
                      i.statusResult.waterLevel &&
                      i.statusResult.waterLevel.length === 0
                    "
                  >
                    正常
                  </div>
                  <div
                    v-else-if="
                      i.statusResult.waterLevel &&
                      i.statusResult.waterLevel.length > 0
                    "
                  >
                    <p v-for="(x, idx) in i.statusResult.waterLevel" :key="idx">
                      {{ x.msg }}
                    </p>
                  </div>
                  <div v-else>無裝置或是未填設定值</div>
                </div>
              </b-popover>
            </tr>
          </table>
        </div>
      </b-overlay>
    </div>
    <b-modal id="settingModal" size="xl" title="設定" hide-footer>
      <b-alert
        :show="configExist"
        variant="secondary"
        class="text-center font-weight-bold mt-3"
      >
        此案場尚未上傳設定值
      </b-alert>
      <div class="otherTableStyle overflow-auto">
        <table class="w-100 text-center text-nowrap">
          <tr>
            <th>資料異常之判定</th>
            <th>數值</th>
            <th>上限值</th>
            <th>下限值</th>
            <th>備註</th>
          </tr>
          <tr v-for="(i, idx) in settingArray" :key="idx">
            <td v-if="idx === 'invs'">
              <span class="mr-2">變流器</span>
              <select v-model="invSelect">
                <option v-for="(j, key, index) in i" :key="index" :value="key">
                  {{ transferConfig(key) }}
                </option>
              </select>
              <!-- {{invSelect}} -->
            </td>
            <td v-else>{{ transferConfig(idx) }}</td>

            <td v-if="i[`${invSelect}`] && idx === 'invs'">
              <input
                class="inputWidth"
                type="text"
                :value="i[`${invSelect}`].unit"
                @input="i[`${invSelect}`].unit = $event.target.value"
              />
            </td>
            <td v-else>
              <input
                class="inputWidth"
                type="text"
                :value="i.unit"
                @input="i.unit = $event.target.value"
              />
            </td>

            <td v-if="i[`${invSelect}`] && idx === 'invs'">
              <input
                class="inputWidth"
                type="number"
                :value="i[`${invSelect}`].max"
                @input="i[`${invSelect}`].max = $event.target.value"
              />
            </td>
            <td v-else>
              <input
                class="inputWidth"
                type="number"
                :value="i.max"
                @input="i.max = Number($event.target.value)"
              />
            </td>

            <td v-if="i[`${invSelect}`] && idx === 'invs'">
              <input
                class="inputWidth"
                type="number"
                :value="i[`${invSelect}`].min"
                @input="i[`${invSelect}`].min = $event.target.value"
              />
            </td>
            <td v-else>
              <input
                class="inputWidth"
                type="number"
                :value="i.min"
                @input="i.min = Number($event.target.value)"
              />
            </td>

            <td v-if="i[`${invSelect}`] && idx === 'invs'">
              <input
                class="inputWidth"
                type="text"
                :value="i[`${invSelect}`].note"
                @input="i[`${invSelect}`].note = $event.target.value"
              />
            </td>
            <td v-else>
              <input
                class="inputWidth"
                type="text"
                :value="i.note"
                @input="i.note = $event.target.value"
              />
            </td>
          </tr>
        </table>
      </div>
      <button
        class="confirmButton p-1 mt-3 d-flex mx-auto"
        style="width: 80px"
        @click="upDateConfig(settingArray)"
      >
        上傳
      </button>
    </b-modal>
    <b-modal
      id="meterModal"
      size="xl"
      :title="meterFactory + ' 電表資料'"
      hide-footer
      v-if="meterData"
    >
      <p>
        資料更新時間:
        {{
          DateTime.fromISO(meterData.timestamp)
            .minus({ hour: 8 })
            .toFormat("yyyy-MM-dd HH:mm:ss")
        }}
      </p>
      <div class="otherTableStyle d-flex text-center">
        <table v-if="meterData.etc['HV-meter']" class="w-100 m-5px">
          <tr>
            <th colspan="2">HV-METER</th>
          </tr>
          <tr
            v-for="(i, idx) in Object.entries(meterData.etc['HV-meter'])"
            :key="idx"
          >
            <td>{{ i[0] }}</td>
            <td>{{ i[1] }}</td>
          </tr>
        </table>
        <table v-if="meterData.etc['LV-meter']" class="w-100 m-5px">
          <tr>
            <th colspan="2">LV-METER</th>
          </tr>
          <tr
            v-for="(i, idx) in Object.entries(meterData.etc['LV-meter'])"
            :key="idx"
          >
            <td>{{ i[0] }}</td>
            <td>{{ i[1] }}</td>
          </tr>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { BModal, BPopover, BAlert, BOverlay } from 'bootstrap-vue'
import {
  getRecentAlarmsApi,
  updateDeviceConfigApi,
  getLastEtcDataApi
} from '../api'
import { DateTime } from 'luxon'
export default {
  name: 'FactoryStatus',
  components: {
    BModal,
    BPopover,
    BAlert,
    BOverlay
  },
  data () {
    return {
      DateTime,
      meterFactory: '',
      meterData: null,
      filterSite: [],
      fakeSettingArray: [
        '(依)變流器flag',
        '電表',
        '日照',
        '模溫',
        '環溫',
        '風速',
        '水位'
      ],
      settingArray: {},
      invSelect: 'irr',
      recentalarmsData: [],
      updateFactory: '',
      updateDocID: '',
      filterTitle: [
        {
          name: '案場',
          sortName: 'factoryName',
          sort: true
        },
        {
          name: '案場編號',
          sortName: 'projectNumber',
          sort: true
        },
        {
          name: '縣市',
          sortName: 'county',
          sort: true
        },
        {
          name: '工程師',
          sortName: 'maintainer',
          sort: true
        },
        {
          name: '工程師工號',
          sortName: 'maintainerNum',
          sort: true
        },
        {
          name: '連線時間',
          sortName: 'timestamp',
          sort: true
        }
      ],
      thTitle: [
        {
          name: '案場',
          sortName: 'factoryName',
          sort: true
        },
        {
          name: '案場編號',
          sortName: 'projectNumber',
          sort: true
        },
        {
          name: '縣市',
          sortName: 'county',
          sort: true
        },
        {
          name: '工程師',
          sortName: 'maintainer',
          sort: true
        },
        {
          name: '工程師工號',
          sortName: 'maintainerNum',
          sort: true
        },
        {
          name: '裝置狀態',
          sortName: 'level',
          sort: false
        },
        {
          name: '連線時間',
          sortName: 'timestamp',
          sort: true
        }
      ],
      searchInput: '',
      selectValue: {
        name: '案場',
        sortName: 'factoryName',
        sort: true
      },
      configExist: true,
      loadingData: false
    }
  },
  computed: {
    ...mapState(['filterSiteArray']),
    factoryStatusFilterSearch () {
      this.recentalarmsData.forEach((x) => {
        if (x.timestamp === null) x.timestamp = ''
      })
      if (this.filterSite.length > 0) {
        return this.recentalarmsData.filter(
          (resources) =>
            resources[`${this.selectValue.sortName}`].match(this.searchInput) &&
            this.filterSite.includes(resources['county'])
        )
      }
      return this.recentalarmsData.filter((resources) =>
        resources[`${this.selectValue.sortName}`].match(this.searchInput)
      )
    }
  },
  methods: {
    goMoxaPage () {
      window.open('https://dlm.thingsprocloud.com/login')
    },
    transferConfig (key) {
      if (key === 'invs') return '變流器'
      if (key === 'meter') return '電表'
      if (key === 'S40') return 'S40'
      if (key === 'irr') return '日照計'
      if (key === 'moduleTemp') return '模組溫度'
      if (key === 'envTemp') return '環溫'
      if (key === 'windSpeed') return '風速'
      if (key === 'waterLevel') return '水位'
      if (key === 'mppt') return 'mppt'
      if (key === 'eff') return '效率'
      if (key === 'eNetV') return '電網電壓'
      if (key === 'eNetFreq') return '電網頻率'
      if (key === 'temp') return '溫度'
      return key
    },
    StatusFunction (obj) {
      const gray = 'color: #787878; opacity: 0.6'
      const red = 'color: #FF2D2D'
      const yellow = 'color: #ffe606'
      const green = 'color: green'
      if (obj === null) return gray
      if (typeof obj === 'boolean') {
        if (obj) {
          return red
        } else {
          return green
        }
      }
      if (obj.length > 0) {
        const noAlert = obj.every((x) => x.noAlert === true)
        if (noAlert) {
          return green
        } else {
          return yellow
        }
      } else {
        return green // fix meter
      }
    },
    alarmsLevel (arr) {
      arr.forEach((x) => {
        const vlevel = 9
        x.level = null
        for (const [index, [key, value]] of Object.entries(
          Object.entries(x.statusResult)
        )) {
          if (key === 'disconnect' && value) x.level = Math.pow(2, 10)
          if (
            value &&
            value.length > 0 &&
            !value.every((y) => y.noAlert === true)
          ) {
            let levelIndex = index
            if (key === 'invsTotalStatus') levelIndex = 1
            x.level = x.level
              ? x.level + Math.pow(2, vlevel - levelIndex)
              : Math.pow(2, vlevel - levelIndex)
          }
          if (value === null) x.level++
        }
        if (!x.level) x.level = 0
      })
      return arr
    },
    openSetting (obj, factoryName, docId, configObj) {
      this.configExist = true
      const defaultConfig = configObj
      this.settingArray = obj !== undefined ? obj : defaultConfig
      // this.invSelect = obj.invs.irr
      this.configExist =
        obj !== undefined ? !this.configExist : this.configExist
      this.updateFactory = factoryName
      this.updateDocID = docId
      this.$bvModal.show('settingModal')
    },
    sortHistoryData (str, idx) {
      this.recentalarmsData = this.recentalarmsData.sort((a, b) => {
        if (a[`${str}`] === null) a[`${str}`] = ''
        if (b[`${str}`] === null) b[`${str}`] = ''
        if (a[`${str}`] > b[`${str}`]) return 1
        if (a[`${str}`] < b[`${str}`]) return -1
        return 0
      })
      if (!this.thTitle[idx].sort) {
        this.recentalarmsData = this.recentalarmsData.reverse()
      }
      this.thTitle[idx].sort = !this.thTitle[idx].sort
    },
    sortAlertMsg (array) {
      array.forEach((x) => {
        if (x.statusResult.invsTotalStatus !== null) {
          const alertArray = x.statusResult.invsTotalStatus.filter(
            (y) => y.noAlert === false
          )
          const noAlertArray = x.statusResult.invsTotalStatus.filter(
            (y) => y.noAlert === true
          )
          const sortArray = alertArray.concat(noAlertArray)
          x.statusResult.invsTotalStatus = sortArray
        }
      })
      return array
    },
    async getRecentAlarms () {
      this.loadingData = true
      this.recentalarmsData = []
      const recentalarms = await getRecentAlarmsApi()
      if (recentalarms?.data?.success) {
        this.rebuildInvsResult(recentalarms.data.data)
        this.recentalarmsData = this.alarmsLevel(recentalarms.data.data)
        this.recentalarmsData = this.sortAlertMsg(this.recentalarmsData)
        this.thTitle[5].sort = false
        this.sortHistoryData('level', 5)
      }
      this.loadingData = false
    },
    rebuildInvsResult (array) {
      array.forEach((x) => {
        if (x.statusResult.invs !== null) {
          const invsObjArray = Object.values(x.statusResult.invs)
          let resultArray = []
          invsObjArray.forEach((y) => {
            if (y !== null) {
              resultArray = resultArray.concat(y)
            }
          })
          x.statusResult.invsTotalStatus = resultArray
        } else {
          x.statusResult.invsTotalStatus = null
        }
      })
    },
    async upDateConfig (arr) {
      const deviceConfig = arr
      const upDateConfig = await updateDeviceConfigApi(
        this.updateFactory,
        this.updateDocID,
        deviceConfig
      )
      if (upDateConfig?.data?.success) {
        this.$swal('上傳成功')
      }
    },
    async showMeter (factoryName, meterExist) {
      if (meterExist === 'color: #787878; opacity: 0.6') return
      this.meterFactory = factoryName
      await this.getlastetcdata(factoryName)
      this.$bvModal.show('meterModal')
    },
    async getlastetcdata (factoryName) {
      const lastEtcData = await getLastEtcDataApi(factoryName)
      if (lastEtcData?.data?.success) {
        this.meterData = lastEtcData.data.data
      }
    }
  },
  created () {
    this.getRecentAlarms()
  }
}
</script>

<style lang="scss" scoped>
.popoverContent {
  font-size: 16px;
  font-weight: 700;
  max-height: 300px;
  overflow-y: auto;
  p {
    padding: 5px 0px;
    margin: 0;
  }
}

.inputWidth {
  width: 100px;
}

table th {
  padding-left: 20px;
  padding-right: 20px;
}

table td {
  font-size: 17px;
  font-weight: 700;
  padding: 7px 15px;
}

.statusIcon {
  font-size: 25px;
  color: gray;
  padding-left: 12px;
  padding-right: 12px;
}

.tableWrap {
  height: calc(100vh - 210px);
  overflow-x: auto;
  min-height: 450px;

  table {
    th {
      background-color: rgb(246, 247, 245);
    }
  }
  @media screen and (max-width: 575.98px) {
    height: calc(100vh - 328px);
  }
}
</style>

<style lang="scss">
// cover css
// popover 文字不被限制大小 (scoped :deep 無作用)
.popover {
  max-width: max-content;
}
</style>
